import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import ModuleArea from '../components/ModularContent/ModuleArea';
import ProductIntro from '../components/Product/ProductIntro';
import ProductNav from '../components/Product/ProductNav';

const ProductPages = ({
  data: {
    datoCmsProduct: {
      title,
      description,
      collection,
      featureImage,
      priceDescriptor,
      priceValue,
      saleDescriptor,
      saleValue,
      seats,
      dimensions,
      jets,
      volume,
      weight,
      content,
      id,
      metaTags,
      productVariants,
    },
    homeCrumb,
    dealerLink,
    contactLink,
    translations
  },
  pageContext,
}) => {
  const product = {
    id,
    title,
    featureImage,
    priceDescriptor,
    priceValue,
    seats,
    dimensions,
    jets,
    volume,
    weight,
  };

  const hasAr = productVariants.length > 0 ? true : false;

  const hasLeadBlock = content.find(
    (obj) => obj.__typename === 'DatoCmsLeadBlock'
  );
  const hasFeaturesBlock = content.find(
    (obj) => obj.__typename === 'DatoCmsProductCardGridBlock'
  );
  const hasColorsBlock = content.find(
    (obj) => obj.__typename === 'DatoCmsProductColorsBlock'
  );
  const hasSpecsBlock = content.find(
    (obj) => obj.__typename === 'DatoCmsSpecificationsBlock'
  );
  const hasWarrantiesBlock = content.find(
    (obj) => obj.__typename === 'DatoCmsWarrantyBlock'
  );

  return (
    <PageWrapper pageData={pageContext} metaTags={metaTags}>
      <ProductIntro
        image={featureImage}
        title={title}
        collection={collection}
        priceDescriptor={priceDescriptor}
        priceValue={priceValue}
        saleDescriptor={saleDescriptor}
        saleValue={saleValue}
        seats={seats}
        dimensions={dimensions}
        jets={jets}
        hasAr={hasAr}
        dealerLink={dealerLink}
        contactLink={contactLink}
        translations={translations}
      />
      <ProductNav
        product={hasLeadBlock}
        features={hasFeaturesBlock}
        colors={hasColorsBlock}
        specification={hasSpecsBlock}
        warranties={hasWarrantiesBlock}
        translations={translations}
      />
      {content?.length > 0 && (
        <ModuleArea
          blocks={content}
          product={product}
          productVariants={productVariants}
        />
      )}
    </PageWrapper>
  );
};

export default ProductPages;

export const query = graphql`
  query ProductPages($locale: String!, $id: String!) {
    dealerLink: datoCmsDealerPage(locale: { eq: $locale }) {
      id: originalId
      locale
    }
    contactLink: datoCmsContactPage(locale: { eq: $locale }) {
      id: originalId
      locale
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
    translations: datoCmsTranslation(locale: { eq: $locale }) {
      id: originalId
      locale
      dimensions
      features
      findARetailerButtonText
      getAQuoteButtonText
      jets
      product
      seating
      specifications
      viewer
      warranties
    }
    datoCmsProduct(locale: { eq: $locale }, originalId: { eq: $id }) {
      locale
      metaTags {
        title
        description
        image {
          url
        }
      }
      title
      id: originalId
      collection {
        id: originalId
        title
      }
      description
      featureImage {
        gatsbyImageData(
          layout: CONSTRAINED
          imgixParams: { h: "550", fit: "crop", q: 60 }
        )
      }
      priceDescriptor
      priceValue
      saleDescriptor
      saleValue
      seats
      dimensions
      jets
      volume
      weight
      content {
        ... on DatoCmsVideoBlock {
          ...VideoBlock
        }
        ... on DatoCmsProductCardGridBlock {
          ...ProductCardGridBlock
        }
        ... on DatoCmsSpecCompareTableBlock {
          ...SpecCompareTableBlock
        }
        ... on DatoCmsProductFeatureSliderBlock {
          ...ProductFeatureSliderBlock
        }
        ... on DatoCmsProductModelCardsBlock {
          ...ProductModelCardsBlock
        }
        ... on DatoCmsAvanteCompareBlock {
          ...AvanteCompareBlock
        }
        ... on DatoCmsSpecificationsBlock {
          ...SpecificationsBlock
        }
        ... on DatoCmsLeadBlock {
          ...LeadBlock
        }
        ... on DatoCmsDetailedInfoBlock {
          ...DetailedInfoBlock
        }
        ... on DatoCmsLeadBlock {
          ...LeadBlock
        }
        ... on DatoCmsJetsBlock {
          ...JetsBlock
        }
        ... on DatoCmsProductGalleryBlock {
          ...ProductGalleryBlock
        }
        ... on DatoCmsWarrantyBlock {
          ...WarrantyBlock
        }
        ... on DatoCmsProductCompareBlock {
          ...ProductCompareBlock
        }
        ... on DatoCmsProductColorsBlock {
          ...ProductColorsBlock
        }
      }
      productVariants {
        internalTitle
        sceneId
        cabinetProductId
        cabinetVariationId
        shellProductId
        shellVariationId
        arProductId
        arVariationId
        cabinetColor {
          title
          swatch {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "485"
                fit: "cover"
                w: "820"
                q: 60
                auto: "format"
              }
            )
            alt
          }
        }
        shellColor {
          title
          swatch {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "485"
                fit: "cover"
                w: "820"
                q: 60
                auto: "format"
              }
            )
            alt
          }
        }
        render {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { fit: "cover", w: "1200", q: 60 }
          )
          alt
        }
      }
    }
  }
`;
