import React from 'react';
import style from './product-nav.mod.scss';
import Scroll from 'react-scroll';

const ScrollLink = Scroll.Link;

const ProductNav = ({
  product,
  features,
  colors,
  specification,
  warranties,
  translations
}) => {
  let links = [];

const productText = translations.product || 'Product'
const featuresText = translations.features || 'Features'
const colorsText = translations.viewer || '3D Viewer'
const specsText = translations.specifications || 'Specification'
const warrantiesText = translations.warranties || 'Warranties'

  product &&
    links.push({
      text: productText,
      link: 'product-overview',
    });

  features &&
    links.push({
      text: featuresText,
      link: 'product-features',
    });

  colors &&
    links.push({
      text: colorsText,
      link: 'product-colors',
    });

  specification &&
    links.push({
      text: specsText,
      link: 'product-specs',
    });

  warranties &&
    links.push({
      text: warrantiesText,
      link: 'product-warranties',
    });

  return (
    <div className={style.nav}>
      <div className={style.nav__wrap}>
        <ul>
          {links.map(({ text, link }) => {
            return <LinkItem text={text} link={link} />;
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProductNav;

const LinkItem = ({ text, link }) => {
  return (
    <li>
      <ScrollLink offset={-60} to={link} spy={true} smooth={true} duration={500}>
        {text}
      </ScrollLink>
    </li>
  );
};
