import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Navigator } from '../LanguageHelpers/Navigator';
import Container from '../Layout/SharedStyles/Container';
import IconFeatureSeat from '../Layout/Icons/IconFeatureSeat';
import IconFeatureJets from '../Layout/Icons/IconFeatureJets';
import IconFeatureDimensions from '../Layout/Icons/IconFeatureDimensions';
import Button from '../Button/Button';
import BgWave from '../Layout/Icons/BgWave';
import Scroll from 'react-scroll';
import style from './product-intro.mod.scss';

const ProductIntro = ({
  title,
  image,
  collection,
  priceDescriptor,
  priceValue,
  saleDescriptor,
  saleValue,
  seats,
  dimensions,
  jets,
  hasAr,
  dealerLink,
  contactLink,
  translations,
}) => {
  return (
    <div className={style.intro}>
      <div className={style.intro__wave}>
        <BgWave />
      </div>
      <Container width="small" pt={2} pb={2}>
        {collection && (
          <p className={style.intro__collection}>
            <Navigator recordId={collection.id}>{collection.title}</Navigator>
          </p>
        )}
        <h1>{title}</h1>
        <p className={style.intro__price}>
          {priceDescriptor} <b>{priceValue}</b>
          {saleValue && (
            <span className={style.intro__sale}>
              {saleDescriptor} <b>{saleValue}</b>
            </span>
          )}
        </p>
        <div className={style.intro__img}>
          <GatsbyImage image={image.gatsbyImageData} alt={image.alt || ''} />
          {hasAr && (
            <Scroll.Link
              className={style.intro__3d}
              offset={-60}
              to="product-colors"
              spy={true}
              smooth={true}
              duration={500}
            >
              <span className="sr-only">view in 3D</span>
            </Scroll.Link>
          )}
        </div>
        <ul className={style.intro__list}>
          {seats && (
            <li>
              <span className={style.intro__listheading}>
                {translations.seating || 'Seating'}
              </span>
              <span className={style.intro__listdetail}>
                <IconFeatureSeat /> {seats}
              </span>
            </li>
          )}
          {dimensions && (
            <li>
              <span className={style.intro__listheading}>
                {translations.dimensions || 'Dimensions'}
              </span>
              <span className={style.intro__listdetail}>
                <IconFeatureDimensions /> {dimensions}
              </span>
            </li>
          )}
          {jets && (
            <li>
              <span className={style.intro__listheading}>
                {translations.jets || 'Jets'}
              </span>
              <span className={style.intro__listdetail}>
                <IconFeatureJets /> {jets}
              </span>
            </li>
          )}
        </ul>
        <div className={style.intro__buttons}>
          <Button recordId={dealerLink.id} primary>
            {translations.findARetailerButtonText || 'Find a retailer'}
          </Button>
          <Button recordId={contactLink.id} primary>
            {translations.getAQuoteButtonText ||'Get a quote'}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default ProductIntro;
